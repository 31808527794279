import React from 'react'
import { AtomicBlockUtils } from 'draft-js'

import style from './HorizontalRule.css'

export function insertHorizontalRule(editorState) {
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity('HR', 'IMMUTABLE', {
    customType: 'horizontal-rule',
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const newState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' ',
  )
  return newState
}

export function HorizontalRuleControl({ className, onChange }) {
  return (
    <span className={className} onClick={() => onChange()}>
      HR
    </span>
  )
}

export default function HorizontalRuleBlock() {
  return <hr className={style.hr} />
}
