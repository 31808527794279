import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import style from './SlidePanel.css'
import { CrossIcon } from '../../icons/CrossIcon.jsx'

export const SlidePanel = ({
  title,
  className,
  open,
  openIcon = 'O',
  openButtonTopPosition = '70px',
  children,
}) => {
  const [openPanel, setOpenPanel] = useState(open)

  useEffect(() => {
    setOpenPanel(open)
  }, [open])

  return (
    <div
      className={classnames(style.container, className, {
        [style.open]: openPanel,
      })}
    >
      <div className={style.title}>{title}</div>
      <div className={style.content}>{children}</div>
      <div
        className={style.closeButton}
        onClick={() => setOpenPanel(!openPanel)}
        style={{ top: openButtonTopPosition }}
      >
        {openPanel ? <CrossIcon color="#d76acd" /> : openIcon}
      </div>
    </div>
  )
}
