import React from 'react'
import classnames from 'classnames'

import style from './ListHeader.css'

export function ListHeader({ className, columns }) {
  return (
    <div className={classnames(style.listHeader, className)}>
      <div className={style.columnNames}>
        {columns.map((column, id) => (
          <span key={id} className={column.className}>
            {column.name}
          </span>
        ))}
      </div>
    </div>
  )
}
