import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { convertToKebabCase } from '../../../utils/index.js'
import style from './style.css'

export const Tabs = ({
  selectedTabIndex,
  items,
  onClick,
  headerClassName,
  contentClassName,
}) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex)
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setSelectedTab(selectedTabIndex)
  }, [selectedTabIndex])

  useEffect(() => {
    const newTabs = []

    items.forEach((item, index) => {
      newTabs.push({
        name: (
          <a
            className={classnames(style.tab, {
              [style.selected]: index === selectedTab,
            })}
            data-test={`tab-${convertToKebabCase(item.name)}`}
            key={convertToKebabCase(item.name)}
            onClick={(event) =>
              handleOnClick(event, { name: item.name, url: item.url, index })
            }
          >
            {item.name}
          </a>
        ),
      })
    })

    setTabs(newTabs)
  }, [items, selectedTab])

  const handleOnClick = (event, item) => {
    event.preventDefault()
    setSelectedTab(item.index)
    onClick(item)
  }

  const TabContent = items[selectedTab] && items[selectedTab].Content

  return (
    <div className={style.container}>
      <div className={classnames(style.tabNames, headerClassName)}>
        {tabs.map(({ name }) => name)}
      </div>

      <div className={classnames(style.tabContent, contentClassName)}>
        {TabContent && <TabContent />}
      </div>
    </div>
  )
}
