import React from 'react'
import { RichUtils } from 'draft-js'

import BlockStyleControls from './BlockStyleControls.jsx'
import InlineStyleControls from './InlineStyleControls.jsx'
import CustomStyleControls from './CustomStyleControls.jsx'
import style from './Toolbar.css'

export default function Toolbar({ editorRef, editorState, onChange }) {
  return (
    <div className={style.toolbar}>
      <BlockStyleControls
        editorRef={editorRef}
        editorState={editorState}
        onChange={onChange}
        RichUtils={RichUtils}
      />
      <InlineStyleControls
        editorRef={editorRef}
        editorState={editorState}
        onChange={onChange}
        RichUtils={RichUtils}
      />

      <CustomStyleControls editorState={editorState} onChange={onChange} />
    </div>
  )
}
