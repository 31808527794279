import React, { useCallback, useState, useEffect } from 'react'
import { Search as SemanticSearch } from 'semantic-ui-react'
import { debounce } from 'lodash'
import classnames from 'classnames'

import style from './Search.css'

/**
 * Given a object mapping returns the value
 */
const extractObjectValue = (objectMapping, data) => {
  if (objectMapping.length === 1) {
    return objectMapping[0]
  }

  return objectMapping.split(/\./).reduce((acc, curr) => {
    return acc[curr]
  }, data)
}

export function Search({
  placeholder = 'Search',
  className,
  loading,
  results,
  resultRenderer,
  showNoResults,
  showResultsOnFocus = false,
  minCharacters,
  icon,
  onFocus,
  onSelect,
  delay = 300,
  onSearchChange,
  onResultSelect,
  selectResultValueMapping,
  searchText = '',
  useAutoCorrect = true,
}) {
  const [value, setSearch] = useState(searchText)

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), delay),
    [],
  )

  useEffect(() => {
    if (!value) {
      setSearch(searchText)
    }
  }, [searchText])

  useEffect(() => {
    debouncedSearch(value)

    return () => debouncedSearch.cancel()
  }, [value])

  const updateSearchValue = (_, { value }) => {
    setSearch(value)
  }

  const updateSelectedResult = (_, { result }) => {
    if (onResultSelect && selectResultValueMapping) {
      const selectedValue = extractObjectValue(selectResultValueMapping, result)
      setSearch(selectedValue)

      onResultSelect(null, { result })
    }
  }

  const params = {
    placeholder,
    className: classnames(
      style.search,
      { [style.icon]: Boolean(icon) },
      className,
    ),
    loading,
    minCharacters: showResultsOnFocus ? 0 : minCharacters,
    icon,
    results,
    onSearchChange: updateSearchValue,
    onResultSelect: updateSelectedResult,
    resultRenderer,
    value,
    showNoResults,
    onFocus,
    onSelect,
  }

  const autoCorrect = useAutoCorrect ? {} : { autoCorrect: 'off' }

  return <SemanticSearch {...{ ...params, ...autoCorrect }} />
}
