import React from 'react'
import classnames from 'classnames'

import style from './Toolbar.css'

export default function InlineStyleControls({
  editorRef,
  editorState,
  onChange,
  RichUtils,
}) {
  const BLOCK_STYLES = [
    { label: 'H2', type: 'header-two', className: style.buttonH2 },
    { label: 'H3', type: 'header-three', className: style.buttonH3 },
    {
      label: 'List item',
      type: 'unordered-list-item',
      className: style.buttonListItem,
    },
  ]
  const selection = editorState.getSelection()
  const currentBlock = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  function toggleBlockTypes(
    event,
    blockType,
    { editorRef, editorState, onChange },
  ) {
    event.preventDefault()
    onChange(RichUtils.toggleBlockType(editorState, blockType))
    editorRef.current.focus()
  }

  return (
    <>
      {BLOCK_STYLES.map((blockType) => (
        <span
          data-test={`toolbar-${blockType.type.toLowerCase()}-button`}
          key={blockType.type}
          className={classnames(style.toolbarButton, blockType.className, {
            [style.buttonActive]: blockType.type === currentBlock,
          })}
          onMouseDown={(event) =>
            toggleBlockTypes(event, blockType.type, {
              editorRef,
              editorState,
              onChange,
            })
          }
        >
          {blockType.label}
        </span>
      ))}
    </>
  )
}
