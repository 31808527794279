import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { Checkbox } from '../Checkbox/Checkbox.jsx'
import cross from './cross.svg'
import style from './style.css'

export const CheckList = ({
  title,
  group,
  items,
  onChange,
  onDeleteClick,
  deepCompareValues,
  showCheckboxes = true,
  showDelete = true,
}) => {
  const [localGroup, setLocalGroup] = useState(group)
  const [localItems, setLocalItems] = useState(items)

  useEffect(
    () => {
      setLocalGroup(group)
    },
    Array.isArray(deepCompareValues) ? deepCompareValues : [group],
  )

  useEffect(
    () => {
      setLocalItems(items)
    },
    Array.isArray(deepCompareValues) ? deepCompareValues : [items],
  )

  const getSelectedItems = (items) => {
    return items.reduce(
      (currentItems, item) =>
        item.checked ? [...currentItems, item] : currentItems,
      [],
    )
  }

  const handleItemClick = (value) => {
    if (group) {
      const newLocalGroup = {
        value: localGroup.value,
        checked: !localGroup.checked,
      }
      setLocalGroup(newLocalGroup)
      onChange(newLocalGroup)
    } else {
      const newLocalItems = localItems.map((item) => {
        return value === item.value ? { ...item, checked: !item.checked } : item
      })
      setLocalItems(newLocalItems)
      onChange(getSelectedItems(newLocalItems))
    }
  }

  return (
    <div className={style.container}>
      <div
        className={classnames(style.item, style.title, {
          [style.handCursor]: group && showCheckboxes,
          [style.readOnly]: !showCheckboxes,
        })}
      >
        {group ? (
          <>
            {showCheckboxes ? (
              <Checkbox
                checkContainerClassName={style.checkbox}
                checked={localGroup.checked}
                value={localGroup.value}
                label={title}
                onChange={handleItemClick}
              />
            ) : (
              title
            )}
            {showDelete && (
              <img
                className={style.crossIcon}
                alt="cross-icon"
                src={cross}
                onClick={() => onDeleteClick && onDeleteClick(localGroup.value)}
              />
            )}
          </>
        ) : (
          title
        )}
      </div>

      {localItems.map((item) => (
        <div
          key={item.value}
          className={classnames(style.item, {
            [style.handCursor]: !group && showCheckboxes,
          })}
        >
          {!group && showCheckboxes && (
            <Checkbox
              checkContainerClassName={style.checkbox}
              checked={item.checked}
              onChange={() => handleItemClick(item.value)}
            />
          )}

          <span
            className={classnames(style.itemText, {
              [style.readOnly]: !showCheckboxes,
            })}
            onClick={() => handleItemClick(item.value)}
          >
            {item.text}
          </span>

          {!group && showDelete && (
            <img
              className={style.crossIcon}
              alt="cross-icon"
              src={cross}
              onClick={() => onDeleteClick && onDeleteClick(item.value)}
              data-test={`delete-icon-${item.value}`}
            />
          )}
        </div>
      ))}
    </div>
  )
}
