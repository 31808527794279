import React, { useState, useEffect, useRef } from 'react'
import { Editor, EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import classnames from 'classnames'

import Toolbar from './Toolbar/Toolbar.jsx'
import HorizontalRuleBlock from './HorizontalRule/HorizontalRule'
import linkDecorator from './LinkDecorator'
import style from './TextEditor.css'

function createEditorStateFromHTML(value) {
  const blocksFromHTML = convertFromHTML(value)
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )

  const newEditorState = EditorState.createWithContent(state, linkDecorator)
  const newStateFocus = EditorState.moveFocusToEnd(newEditorState)
  return newStateFocus
}

/**
 * Gets the last field name passed by parameter ()
 */
function getLastFieldName(currentFieldName) {
  const oldValue = getLastFieldName.value
  getLastFieldName.value = currentFieldName
  return oldValue
}

/**
 * Gets the total number of words.
 */
function getCountWords(state) {
  const plainText = state.getCurrentContent().getPlainText()
  return plainText?.match(/\w+/g)?.length || 0
}

function isEmpty(value) {
  const EMPTY_HTML_STATES = ['<p></p>', '<p><br></p>', '<p></br></p>']

  return EMPTY_HTML_STATES.includes(value)
}

export default function TextEditor({
  className,
  name,
  getData,
  updateField,
  showWordCount = false,
}) {
  const editorRef = useRef(null)
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(linkDecorator),
  )
  const [isInitialized, setInitialized] = useState(false)
  const wordCount = getCountWords(editorState)

  function handleChange(state) {
    const isEmptyText =
      state
        .getCurrentContent()
        .getPlainText()
        .replace(/[ \t\r\n]+$/g, '') === ''

    if (isEmptyText) {
      const newState = EditorState.createEmpty(linkDecorator)
      if (getData(name)) {
        updateField(name, { value: '' })
        setEditorState(newState)
      }
    } else {
      const bodyHtml = stateToHTML(state.getCurrentContent())
      setEditorState(state)
      updateField(name, { value: bodyHtml })
    }
  }

  function handleFocus(event, { editorRef }) {
    event.preventDefault()
    editorRef.current.focus()
  }

  useEffect(() => {
    const lastFieldName = getLastFieldName(name)
    const value = getData(name)

    if (
      value &&
      !isEmpty(value) &&
      (!isInitialized || name !== lastFieldName)
    ) {
      setEditorState(createEditorStateFromHTML(getData(name)))
      setInitialized(true)
    }
  }, [getData(name), name])

  function blockRender(block) {
    if (block.getType() !== 'atomic') {
      return
    }

    return {
      component: HorizontalRuleBlock,
      editable: false,
    }
  }

  return (
    <>
      <div className={classnames(className, style.container)}>
        <Toolbar
          editorRef={editorRef}
          editorState={editorState}
          onChange={(state) => handleChange(state)}
        />
        <div
          className={style.editor}
          onClick={(event) => handleFocus(event, { editorRef })}
        >
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={(state) => handleChange(state)}
            webDriverTestID={`${name}-input`}
            blockRendererFn={blockRender}
          />
        </div>
      </div>
      {showWordCount && (
        <div className={style.wordCount}>Word Count: {wordCount}</div>
      )}
    </>
  )
}
