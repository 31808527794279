import React from 'react'
import classnames from 'classnames'

import style from '../style.css'
import { useForm } from '../Form/FormContext'
import TextEditor from './TextEditor.jsx'

export default function RteField({
  label,
  name,
  required,
  showErrors = true,
  showWordCount = false,
  className,
}) {
  const { getData, updateField, getFormattedError } = useForm()
  const error = getFormattedError(name)

  return (
    <div
      className={classnames(style.formField, className)}
      {...{ required, error }}
    >
      {label && (
        <label
          className={classnames(style.formFieldLabel, {
            [style.formFieldLabelError]: error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <TextEditor
        {...{ name, getData, updateField, showWordCount, className }}
      />

      {error && showErrors && (
        <div
          data-test={`${name}-error-message`}
          className={style.formFieldErrorMessage}
        >
          {error}
        </div>
      )}
    </div>
  )
}
