import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import style from './style.css'

export const Checkbox = ({
  backgroundColor,
  label,
  value,
  disabled,
  checked = false,
  onChange,
  className,
  checkContainerClassName,
}) => {
  const [localChecked, setLocalChecked] = useState(checked)

  useEffect(() => {
    setLocalChecked(checked)
  }, [checked])

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLocalChecked(!localChecked)

    if (onChange) {
      onChange({ checked: !localChecked, value })
    }
  }

  const styleAttribute = {
    style: {
      backgroundColor:
        backgroundColor && localChecked ? backgroundColor : 'inherit',
    },
  }

  return (
    <div
      className={classnames(style.container, className, {
        [style.disabled]: disabled,
      })}
      onClick={disabled ? undefined : handleClick}
    >
      <div
        className={classnames(style.checkContainer, checkContainerClassName)}
      >
        <input
          type="checkbox"
          className={classnames(style.checkbox, { [style.disabled]: disabled })}
          checked={localChecked}
          disabled={disabled}
          onChange={() => event.preventDefault()}
        />
        <span className={style.checkmark} {...styleAttribute}></span>
      </div>
      {label && <label className={style.label}>{label}</label>}
    </div>
  )
}
