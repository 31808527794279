import React from 'react'
import classnames from 'classnames'

import style from './Toolbar.css'

export default function InlineStyleControls({
  editorRef,
  editorState,
  onChange,
  RichUtils,
}) {
  const INLINE_STYLES = [
    { label: 'Bold', type: 'BOLD', className: style.buttonBold },
    { label: 'Italic', type: 'ITALIC', className: style.buttonItalic },
  ]
  const currentStyle = editorState.getCurrentInlineStyle()

  function toggleInlineStyle(
    event,
    inlineStyle,
    { editorRef, editorState, onChange },
  ) {
    event.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    editorRef.current.focus()
  }

  return (
    <>
      {INLINE_STYLES.map((inlineStyle) => (
        <span
          data-test={`toolbar-${inlineStyle.type.toLowerCase()}-button`}
          key={inlineStyle.type}
          className={classnames(style.toolbarButton, inlineStyle.className, {
            [style.buttonActive]: currentStyle.has(inlineStyle.type),
          })}
          onMouseDown={(event) =>
            toggleInlineStyle(event, inlineStyle.type, {
              editorRef,
              editorState,
              onChange,
            })
          }
        >
          {inlineStyle.label}
        </span>
      ))}
    </>
  )
}
