import React, { useState } from 'react'
import { Modal, Button, Form } from 'semantic-ui-react'

import { RichUtils } from 'draft-js'

function handleCancel({ setShowLinkSourceModal }) {
  setShowLinkSourceModal(false)
}

function handleConfirm(event, url, { editorState, onChange }) {
  event.preventDefault()

  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: url.replace(/\s/g, ''),
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const nextState = RichUtils.toggleLink(
    editorState,
    editorState.getSelection(),
    entityKey,
  )
  onChange(nextState)
}

function handleURLChange(event, { setURL }) {
  setURL(event.target.value)
}

function handleLinkInputKeyDown(event, url, { editorState, onChange }) {
  if (event.which === 13) {
    handleConfirm(event, url, { editorState, onChange })
  }
}

export default function LinkModal({
  editorState,
  onChange,
  setShowLinkSourceModal,
}) {
  const inputRef = React.createRef()
  const [url, setURL] = useState('')

  return (
    <Modal
      size="tiny"
      open
      onMount={() => {
        inputRef.current.focus()
      }}
    >
      <Modal.Header>Link URL</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="link-url">
              Please insert the Link URL:
              <input
                id="link-url"
                type="text"
                ref={inputRef}
                placeholder="www.example.com"
                value={url}
                onChange={(event) => handleURLChange(event, { setURL })}
                onKeyDown={(event) =>
                  handleLinkInputKeyDown(event, url, { editorState, onChange })
                }
              />
            </label>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onMouseDown={() => handleCancel({ setShowLinkSourceModal })}
        >
          Cancel
        </Button>
        <Button
          primary
          content="Set Link URL"
          onMouseDown={(event) =>
            handleConfirm(event, url, { editorState, onChange })
          }
        />
      </Modal.Actions>
    </Modal>
  )
}
