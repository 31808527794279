import React, { useState } from 'react'
import classnames from 'classnames'

import LinkModal from './LinkModal.jsx'
import {
  HorizontalRuleControl,
  insertHorizontalRule,
} from '../HorizontalRule/HorizontalRule'
import { LinkIcon } from '../../../icons/light.jsx'
import style from './Toolbar.css'

export default function CustomStyleControls({ editorState, onChange }) {
  const [showLinkSourceModal, setShowLinkSourceModal] = useState(false)

  function toggleLinkSourceModal(event, { setShowLinkSourceModal }) {
    event.preventDefault()
    setShowLinkSourceModal(true)
  }

  function handleLinkModalChange(
    newState,
    { setShowLinkSourceModal, onChange },
  ) {
    onChange(newState)
    setShowLinkSourceModal(false)
  }

  function handleHorizontalRuleChange(editorState) {
    const newState = insertHorizontalRule(editorState)
    onChange(newState)
  }

  return (
    <>
      <HorizontalRuleControl
        className={style.toolbarButton}
        onChange={() => handleHorizontalRuleChange(editorState)}
      />
      <span className={style.separator}>&#124;</span>
      <span
        data-test="toolbar-link-button"
        className={classnames(style.toolbarButton)}
        onMouseDown={() =>
          toggleLinkSourceModal(event, { setShowLinkSourceModal })
        }
      >
        <LinkIcon />
      </span>
      {showLinkSourceModal && (
        <LinkModal
          editorState={editorState}
          setShowLinkSourceModal={setShowLinkSourceModal}
          onChange={(newState) =>
            handleLinkModalChange(newState, {
              setShowLinkSourceModal,
              onChange,
            })
          }
        />
      )}
    </>
  )
}
